import React from 'react'
import {
  Box,
  Heading,
  Link,
  Text,
  useColorModeValue,
  Container,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const ArticleList = () => {
  return (
    <>
      <Container maxW={'7xl'} p="12">
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
            >
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                <StaticImage
                  src="../images/building2.jpeg"
                  alt="image"
                  style={{ width: '100%', borderRadius: '10px' }}
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  'radial(red.600 1px, transparent 1px)',
                  'radial(red.300 1px, transparent 1px)',
                )}
                backgroundSize="20px 20px"
                opacity="0.5"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}
          >
            <Heading marginTop="1">
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                Üzemeltetés
              </Link>
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue('gray.700', 'gray.200')}
              fontSize="lg"
            >
              Épület állapot felmérése, mechanikai és elektronikai rendszerek
              javítása annak érdekében, hogy azok megfeleljenek az egészségügyi
              és biztonsági előírásoknak.
            </Text>
          </Box>
        </Box>
      </Container>

      <Container maxW={'7xl'} p="12">
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
            >
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                <StaticImage
                  src="../images/cleaning4.jpeg"
                  alt="image"
                  style={{ width: '100%', borderRadius: '10px' }}
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  'radial(red.600 1px, transparent 1px)',
                  'radial(red.300 1px, transparent 1px)',
                )}
                backgroundSize="20px 20px"
                opacity="0.5"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}
          >
            <Heading marginTop="1">
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                Takarítás
              </Link>
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue('gray.700', 'gray.200')}
              fontSize="lg"
            >
              Mindenre kiterjedő professzionális takarítási szolgáltatásunk.
              Vállaljuk lakóparkok, társasházak, irodaházak, irodák és
              bevásárlóközpontok illetve mélygarázsok gépi takarítását, valamint
              vírusmentesítését és fertőtlenítését.
            </Text>
          </Box>
        </Box>
      </Container>

      <Container maxW={'7xl'} p="12">
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
            >
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                <StaticImage
                  src="../images/gard3.jpeg"
                  alt="image"
                  style={{ width: '100%', borderRadius: '10px' }}
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  'radial(red.600 1px, transparent 1px)',
                  'radial(red.300 1px, transparent 1px)',
                )}
                backgroundSize="20px 20px"
                opacity="0.5"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}
          >
            <Heading marginTop="1">
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                Zöldterület-kezelés
              </Link>
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue('gray.700', 'gray.200')}
              fontSize="lg"
            >
              Vállaljuk kertek kézi és gépi tereprendezését, területszintezést
              füvesítést, gyepszőnyeg, vakondháló fektetését, automata
              öntözőrendszerek telepítését, kerti tó, patak, csobogó,
              díszmedence kivitelezését, illetve bográcsozó, grillező építését.
            </Text>
          </Box>
        </Box>
      </Container>

      <Container maxW={'7xl'} p="12">
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
            >
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                <StaticImage
                  src="../images/sec1.jpeg"
                  alt="image"
                  style={{ width: '100%', borderRadius: '10px' }}
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  'radial(red.600 1px, transparent 1px)',
                  'radial(red.300 1px, transparent 1px)',
                )}
                backgroundSize="20px 20px"
                opacity="0.5"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}
          >
            <Heading marginTop="1">
              <Link
                textDecoration="none"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
              >
                Személybiztonsági tevékenységek
              </Link>
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue('gray.700', 'gray.200')}
              fontSize="lg"
            >
              Vállaljuk irodaházak, lakóparkok, társasházak élőerős 24 órás
              védelmét, valamint kultúrális- és sportrendezvények, rendvédelmi
              biztosítását. Szolgáltatásunk keretében vállaljuk biztonsági és
              kamerarendszerek tervezését, telepítését és karbantartását.
            </Text>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default ArticleList
